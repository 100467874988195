import React from "react";
import style from "./News.module.css";
import { Helmet } from "react-helmet";
import { getCannonicalUrl } from "../../helper/helper";
import Logo from "../../asset/Image/Season_1.jpeg";
import Breadcrumbs from "../../Components/Breadcrumbs/Breadcrumbs";
import EventImage1 from "../../asset/Image/EventImage1.png";
import EventImage2 from "../../asset/Image/EventImage2.png";
import EventImage3 from "../../asset/Image/EventImage3.png";

import news1 from "../../asset/news/dancenews1.webp";
import news2 from "../../asset/news/dancenews2.png";
import news3 from "../../asset/news/dancenews3.jpg";
import news4 from "../../asset/news/dancenews4.jpg";
import news5 from "../../asset/news/dancenews5.jpg";
import news6 from "../../asset/news/dancenews6.jpg";
import news7 from "../../asset/news/dancenews7.png";
import news8 from "../../asset/news/dancenews8.jpg";
import news9 from "../../asset/news/dancenews9.webp";
import news11 from "../../asset/news/dancenews17.jpg";
import news12 from "../../asset/news/Hamnava.jpeg";
import news13 from "../../asset/news/Sakaal.jpg";

export const News = () => {
  const currentUrl = window.location.href;
  const pathname = new URL(currentUrl).pathname;
  const isProdEnv = process.env.REACT_APP_NODE_ENV_DD === "production";
  const seo_title = "Latest Dance News & Updates | Dance Dynamite India";
  const seo_description =
    "Stay updated with the latest dance news, events, trends, and performances in India. Explore exciting articles, inspiring stories, and exclusive updates from Dance Dynamite India!";
  let breadcrumbsArr = [
    { label: "Home ", link: "/" },
    { label: "News", link: "/news" },
  ];

  let sdata = JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "BreadcrumbList",
    name: "Dance Dynamite News",
    itemListElement: [
      {
        "@type": "ListItem",
        position: 1,
        name: "Home",
        item: "https://dancedynamiteindia.com",
      },
      {
        "@type": "ListItem",
        position: 2,
        name: "News",
        item: "https://dancedynamiteindia.com/news",
      },
    ],
  });

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCannonicalUrl(pathname)} />
        <meta
          name="googlebot"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <meta
          name="robots"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <title>{seo_title} </title>
        <meta name="description" content={seo_description} />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
        <script
          type="application/ld+json"
          dangerouslySetInnerHTML={{ __html: sdata }}
        />
        <link
          rel="stylesheet"
          href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
        />
      </Helmet>
      <div
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <div className={style.breadcrumbs}>
          <Breadcrumbs breadcrumbs={breadcrumbsArr} />
        </div>
        <div className={style.contents}>
          <div className={style.details}>
            <h1>Dance Dynamite News</h1>
          </div>
          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.youtube.com/post/Ugkx7XsvZLy_g5IlYXGoB_wTezhjHg2CZrbU?si=8fqQAJqJimniJKYC"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Pune satta" src={news1} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Pune Satta</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://puneprahar.com/2025/01/winners-crowned-at-dance-dynamite-season-2-indias-biggest-student-dance-platform/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Pune Prahar" src={news2} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Pune Prahar</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://youtu.be/eX6BnLTSXL4?si=Mn0rn19Xfz6BV6MV"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Pune Jagar News" src={news3} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Pune Jagar</div></div>
              </a>
            </div>
          </div>
          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.youtube.com/watch?si=ALxYZm6O_ZBsYhSv&v=HPp4NE6nBNQ&feature=youtu.be"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Saksham News" src={news4} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Saksham News</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.youtube.com/watch?si=AB38etjQGcwdxY0k&v=DM3PVHfMALk&feature=youtu.be"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Darpan News" src={news5} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Darpan News</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://maharashtrawadi.blogspot.com/2025/01/2.html?m=1"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Maharashtrawadi News" src={news6} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Maharashtrawadi News</div></div>
              </a>
            </div>
          </div>
          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://businessnewsthisweek.com/news/students-clinch-victory-in-dance-dynamite-season-2-finale/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Business News This Week" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Business News This Week</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.youtube.com/watch?si=r8r879mInFDU2MXD&v=pXRjkJwIC5I&feature=youtu.be"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Media world" src={news8} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Media World</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.tv9marathi.com/maharashtra/pune/over-200-participants-from-14-cities-unite-to-perform-in-a-thrilling-showcase-of-creativity-dance-styles-in-dance-dynamite-season-2-1330545.html"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="TV 9 Marathi" src={news9} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>TV 9 Marathi</div></div>
              </a>
            </div>
          </div>
          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://edu-news.net/news/students-named-winners-of-dance-dynamite-season-2/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Edu News" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Edu News</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://divasratri.com/dance-dynamite-season-2-celebrating-the-stars-of-indias-biggest-student-dance-platform/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Divas Ratri" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Divas Ratri</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://tarankit.com/21869/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Tarankit" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Tarankit</div></div>
              </a>
            </div>
          </div>
          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://businessnewsmatters.com/news/dance-dynamite-season-2-students-celebrate-victory-as-champions/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Business News Matters" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Business News Matters</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://nationalbiznews.com/news/students-triumph-in-dance-dynamite-season-2-competition/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="National Biz News" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>National Biz News</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://hellokotpad.com/news/dance-dynamite-season-2-students-take-the-crown-as-winners/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Hello Kotpad" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Hello Kotpad</div></div>
              </a>
            </div>
          </div>

          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://mediaexpress24.com/news/students-crowned-winners-of-dance-dynamite-season-2/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Media Express24" src={news7} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Media Express24</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://www.youtube.com/watch?v=WIsVzyfJLkc"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Avani News Express" src={news11} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Avani News Express</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://dancedynamiteindia.com/asset/news/Hamnava.jpeg"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Ham nava" src={news12} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Hamnava</div></div>
              </a>
            </div>
          </div>

          <div className="news-banner">
            <div className="news-container">
              <a
                class="no-style"
                href="https://dancedynamiteindia.com/asset/news/Sakaal.jpg"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Sakaal" src={news13} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2025-01-06 • <i class="fa fa-user" aria-hidden="true"></i>Sakaal</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://indiatalkslive.com/orchids-the-international-school-concludes-dance-dynamite-with-a-grand-finale-featuring-sushant-pujari-of-abcd-fame/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="India Talks Live" src={EventImage1} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2024-01-11 • <i class="fa fa-user" aria-hidden="true"></i>India Talks Live</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://zetanews24x7.com/orchids-the-international-school-concludes-dance-dynamite-with-a-grand-finale-featuring-sushant-pujari-of-abcd-fame/"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Zeta News" src={EventImage2} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2024-01-11 • <i class="fa fa-user" aria-hidden="true"></i>Zeta News</div></div>
              </a>
            </div>
            <div className="news-container">
              <a
                class="no-style"
                href="https://devbhoomibulletin.com/?p=12834"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img alt="Dev Bhoomi Bulletin" src={EventImage3} />
                <div className={style.p4}><div className={style.newstitle}><i class="fa fa-calendar" aria-hidden="true"></i> 2024-01-11 • <i class="fa fa-user" aria-hidden="true"></i>Dev Bhoomi Bulletin</div></div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
