import React from "react";
import style from "./Footer.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faYoutube,
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
import { faEnvelopeOpen } from "@fortawesome/free-solid-svg-icons";
const Footer = () => {
  return (
    <div className={style.footer}>
      <p>Copyright @ 2025 | Dance Dynamite ® </p>
      <p className={style.footeremail}>
        <a href="mailto:dancedynamiteindia@gmail.com">
          <FontAwesomeIcon icon={faEnvelopeOpen} /> dancedynamiteindia@gmail.com
        </a>
      </p>
      <div className={style.footersocial}>
        <p>
          <a href="https://www.facebook.com/profile.php?id=61560171257639">
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
        </p>
        <p>
          <a href="https://www.instagram.com/dancedynamite_india_/?igsh=MXU2dms3eTM0NHAxYg%3D%3D">
            <FontAwesomeIcon icon={faInstagramSquare} />
          </a>
        </p>
        <p>
          <a href="https://youtu.be/1tPFrq8NmTw?si=ki5ZXcdg3EyhM7BS">
            <FontAwesomeIcon icon={faYoutube} />
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
