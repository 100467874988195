import React, { useEffect, useState } from "react";
import { Form, Input, Select, Spin, message } from "antd";
import style from "./RegistrationForm.module.css";
import { useLocation } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { SuccessMessage } from "../Result/SuccessMessage";
const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const RegistrationForm = ({ isOpen }) => {
  const [selected, setSelected] = useState();
  const [showNevtaNote, setShowNevtaNote] = useState(false);
  const changeSelectOptionHandler = (event) => {
    setSelected(event);
  };
  /** Type variable to store different array for different dropdown */
  let type = null;

  /** This will be used to create set of options that user will see */
  let options = null;

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  const [utmSource, setUtmSource] = useState(null);
  const [utmMedium, setUtmMedium] = useState(null);
  const [utmCampaign, setUtmCampaign] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [cityList, setCityList] = useState([]);
  const [branches, setBranches] = useState([]);
  const [cityId, setCityId] = useState("");
  const [selectedBranch, setSelectedBranch] = useState("");
  const [form] = Form.useForm();
  // const formRef = React.useRef();
  const query = useQuery();
  const date = new Date().toLocaleDateString();
  const { Option } = Select;
  const [isSubmitted, setIsSubmitted] = useState(false);
  const unwantedCities = [
    "Bidar",
    "Vijayanagar",
    "Tumkur",
  ];

  const replaceBranchName = (branchName) => {
    if (branchName === "ORCHIDS Langar House") {
      return "ORCHIDS Langar Houz";
    }    
    return branchName;
  };

  const replaceCityName = (cityName) => {
    if (cityName === "pali") {
      return "Pali (Jodhpur)";
    }    
    return cityName;
  };

  const unwantedBranch = [
    "ORCHIDS Tumkur",
    "ORCHIDS Thoraipakkam",
    "ORCHIDS Saran Nagar",
    "ORCHIDS Training",
    "ORCHIDS Khatedhan",
    "ORCHIDS Sonarpur",
    "ORCHIDS Vashi",
    "ORCHIDS Kharadi",
    "SchooviKids Sahakar Nagar",
    "ORCHIDS BTM Annex",
    "ORCHIDS Vijaynagar Annex",
    "ORCHIDS Jubilee Hills Annex",    
    "OCSE SHASTRI NAGAR",   
    "OCSE BANAR",
    "OCSE KUDI",
    "OCSE BANKAROTA",
    "OCSE MAHAVEER NAGAR",
    "OCSE PRATAP NAGAR",
    "ORCHIDS Sonipat",
    "ORCHIDS Majestic",
    "ORCHIDS Sus Pashan Road",
    "ORCHIDS Undri",
    "OCSE PALI-NG"
  ];  

  useEffect(() => {
    axios
      .get("https://marketing.letseduvate.com/qbox/CityList/")
      .then((res) => {
        const filteredData = res?.data?.filter((city) => {
          return (
            !unwantedCities || !unwantedCities.includes(city?.city_name)           
          );
        }).map(city => ({
          ...city,
          city_name: replaceCityName(city.city_name)
        }));  
        setCityList(filteredData);
      })
      .catch((error) => {
        console.log(error?.message);
      });
  }, []);

  const fetchCityWiseBranch = (id) => {
    axios
      .get(
        `https://marketing.letseduvate.com/qbox/LocationWiseBranch/?location=${id}`
      )
      .then((res) => {
        const filteredBranch = res?.data?.filter((branch) => {
          return (
            !unwantedBranch || !unwantedBranch.includes(branch?.branch_name)
          );
        }).map(branch => ({
          ...branch,
          branch_name: replaceBranchName(branch.branch_name)
        }));
        setBranches(filteredBranch);
      })
      .catch((err) => console.log(err?.message));
  };

  const handleCityChange = (value, option) => {
    setCityId(option?.key);
    form.setFieldsValue({ branch: null });
    setBranches([]);
    setShowNevtaNote(false)
  };

  useEffect(() => {
    if (cityId) {
      fetchCityWiseBranch(cityId);
    }
  }, [cityId]);

  useEffect(() => {
    const source = query.get("utm_source");
    const medium = query.get("utm_medium");
    const campaign = query.get("utm_campaign");

    setUtmSource(source ?? null);
    setUtmMedium(medium === "affiliate" ? "Affiliate" : medium ?? null);
    setUtmCampaign(campaign ?? null);
  }, [query]);

  const onSubmit = (values) => {
    setSubmitting(true);
    const formData = new FormData();

    Object.keys(values)?.map((key, index) => {
      if (values[key] === undefined) {
        values[key] = null;
      } else {
        formData.append(key, values[key]);
      }
    });
    const url = window?.location?.href;
    formData.append("utm_source", utmSource);
    formData.append("utm_medium", utmMedium);
    formData.append("utm_campaign", utmCampaign);
    formData.append("Date", date);
    formData.append("URL", url);
    axios
      .post(
        "https://orchidsinternational-cms.stage-gke.letseduvate.com/api/apiV1/google-sheet-open/",
        formData
      )
      .then((res) => {
        // message.success("Thank You! Registration Successfull");
        setSubmitting(false);
        setIsSubmitted(true);
        // console.log([...formData.entries()]);
      })
      .catch((error) => {
        message.error(
          error?.response?.data?.message ??
            "Something went wrong! Please try again"
        );
        setSubmitting(false);
      })
      .finally(() => {
        setSubmitting(false);
        form.resetFields();
      });
  };

  useEffect(() => {
    let timer;
    if (isSubmitted) {
      timer = setTimeout(() => {
        setIsSubmitted(false);
      }, 5000);
    }

    // Cleanup function to clear the timeout if component unmounts or `IsSubmitted` changes
    return () => clearTimeout(timer);
  }, [isSubmitted, form]);

  return (
    <>
      {!isSubmitted ? (
        <div className={style.registrationForm}>
          <div>
            <p>{isOpen ? "Registration Form" : "Registration Closed"}</p>
            <Form
              name="registrationform"
              form={form}
              onFinish={onSubmit}
              layout="vertical"
            >
              <Form.Item
                name="student_name"
                rules={[{ required: true, message: "Name is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="Student name*" />
              </Form.Item>
              <Form.Item
                name="parent_name"
                rules={[{ required: true, message: "Parent name is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="Parents name*" />
              </Form.Item>
              <Form.Item
                name="grade"
                rules={[{ required: true, message: "Grade is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="Grade/Stand*" />
              </Form.Item>
              <Form.Item
                name="group"
                rules={[{ required: true, message: "Group is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Select
                  onChange={changeSelectOptionHandler}
                  placeholder="Select Category*"
                  style={{
                    textAlign: "left",
                  }}
                  options={[
                    { value: "Solo", label: <span>Solo Dance</span> },
                    { value: "Group", label: <span>Group Dance</span> },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="count"
                rules={[
                  {
                    required: selected === "Group",
                    message: "Group Count is required",
                  },
                ]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Select
                  disabled={selected != "Group"}
                  placeholder="Select Count*"
                  style={{
                    textAlign: "left",
                  }}
                  options={[
                    { value: "4", label: <span>4</span> },
                    { value: "5", label: <span>5</span> },
                    { value: "6", label: <span>6</span> },
                    { value: "7", label: <span>7</span> },
                    { value: "8", label: <span>8</span> },
                    { value: "9", label: <span>9</span> },
                    { value: "10", label: <span>10</span> },
                    { value: "11", label: <span>11</span> },
                    { value: "12", label: <span>12</span> },
                  ]}
                />
              </Form.Item>
              <Form.Item
                name="school_name"
                rules={[{ required: true, message: "School is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="School Name or NA*" />
              </Form.Item>
              <Form.Item
                name="contact_person_no"
                rules={[
                  {
                    required: true,
                    message: "Please enter numeric digits",
                    pattern: /^[0-9]*$/,
                  },
                  { max: 10, message: "Please enter up to 10 digits." },
                ]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="Mobile no.*" maxLength={10} />
              </Form.Item>
              <Form.Item
                name="email"
                rules={[{ required: true, message: "Email is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="email" placeholder="your@email.com*" />
              </Form.Item>
              <Form.Item
                name="address"
                rules={[{ required: true, message: "Address is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Input type="text" placeholder="Address*" />
              </Form.Item>
              <Form.Item
                name="city"
                rules={[{ required: true, message: "City is required" }]}
                style={{
                  marginBottom: "10px",
                }}
              >
                <Select
                  placeholder="Select City*"
                  style={{
                    textAlign: "left",
                  }}
                  onChange={handleCityChange}
                  options={cityList?.map((each) => ({
                    value: each?.city_name,
                    label: each?.city_name,
                    key: each?.id,
                  }))}
                />
              </Form.Item>
              <Form.Item
                name="branch"
                rules={[{ required: true, message: "Branch is required" }]}
                style={{
                  marginBottom: "15px",
                }}
              >
                <Select
                  placeholder="Select branch"
                  style={{
                    textAlign: "left",
                  }}
                  disabled={!cityId}
                  onChange={(value) => {
                    form.setFieldsValue({ branch: value });
                    setSelectedBranch(value);
                    setShowNevtaNote(value === "ORCHIDS Nevta");
                  }}
                >
                  {branches?.map((each) => (
                    <Option key={each?.branch_name} value={each?.branch_name}>
                      {each?.branch_name}
                    </Option>
                  ))}
                </Select>
           
              </Form.Item>
              {showNevtaNote && (
  <div style={{ marginTop: '-10px', marginBottom: '15px', fontStyle: 'italic', color:"red" }}>
    *Audition Date for Jaipur, Nevta is August 25<sup>th</sup> 2024
  </div>)}
            </Form>
            <div className={style.formButton}>
              <button
                htmlType="submit"
                form="registrationform"
                disabled={!isOpen}
                style={{
                  backgroundColor: isOpen ? "#d4af37" : "#c7c7c7",
                }}
              >
                {submitting ? (
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{
                          fontSize: 24,
                          color: "#ffffff",
                        }}
                        spin
                      />
                    }
                  />
                ) : (
                  "Register Now"
                )}
              </button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <SuccessMessage />
        </div>
      )}
    </>
  );
};

export default RegistrationForm;
