import React from "react";
import "./HomePage.css";
// import EventImage1 from "../../asset/Image/EventImage1.png";
// import EventImage2 from "../../asset/Image/EventImage2.png";
// import EventImage3 from "../../asset/Image/EventImage3.png";
import Gallery1 from "../../asset/Image/gallery1.jpg";
import Gallery2 from "../../asset/Image/gallery2.jpg";
import Gallery3 from "../../asset/Image/gallery3.jpg";
import Gallery4 from "../../asset/Image/gallery4.jpg";
import Gallery5 from "../../asset/Image/gallery5.jpg";
import Gallery6 from "../../asset/Image/gallery6.jpg";
import Gallery7 from "../../asset/Image/gallery7.jpg";
import Season1Img from "../../asset/Image/season1-min.png";
import Season2Img from "../../asset/Image/season2-min.png";
import { Helmet } from "react-helmet";
import { getCannonicalUrl } from "../../helper/helper";
import Logo from "../../asset/Image/dance-dynamite-logo.jpeg";
import Carousel1 from "../../Carousel";
//import slider1Img from "../../asset/Banner/dance-dynamite-slider-1-min.jpg";
//import slider2Img from "../../asset/Banner/dance-dynamite-slider-2-min.jpg";
// import auditionResults from "../../asset/Banner/audition-results.jpg";
// import result1 from "../../asset/Banner/solo-sub-junior.jpg";
// import result7 from "../../asset/Banner/solo-sub-junior-1.jpg";
// import result2 from "../../asset/Banner/solo-junior.jpg";
// import result6 from "../../asset/Banner/solo-junior-1.jpg";
// import result3 from "../../asset/Banner/solo-senior.jpg";
// import result8 from "../../asset/Banner/solo-senior-1.jpg";
// import result4 from "../../asset/Banner/group-junior.jpg";
// import result9 from "../../asset/Banner/group-junior-1.jpg";
// import result5 from "../../asset/Banner/group-senior.jpg";
// import result10 from "../../asset/Banner/group-senior-1.jpg";

import season21 from "../../asset/Banner/banner-1-desktop-season-2.jpg";
import season22 from "../../asset/Banner/banner-2-desktop-season-2.jpg";
import season23 from "../../asset/Banner/banner-3-desktop-season-2.jpg";
import season24 from "../../asset/Banner/banner-4-desktop-season-2.jpg";
import season25 from "../../asset/Banner/banner-5-desktop-season-2.jpg";
import season26 from "../../asset/Banner/banner-6-desktop-season-2.jpg";

import { Carousel } from "antd";
const images = [
  Gallery1,
  Gallery2,
  Gallery3,
  Gallery4,
  Gallery5,
  Gallery6,
  Gallery7,
];

const sliderImages = [
  // {
  //   id: 1,
  //   image: auditionResults,
  // },
  {
    id: 2,
    image: season21,
  },
  {
    id: 3,
    image: season22,
  },
  {
    id: 4,
    image: season23,
  },
  {
    id: 5,
    image: season24,
  },
  {
    id: 6,
    image: season25,
  },
  {
    id: 7,
    image: season26,
  },
  // {
  //   id: 2,
  //   image: result1,
  // },
  // {
  //   id: 3,
  //   image: result7,
  // },
  // {
  //   id: 4,
  //   image: result2,
  // },
  // {
  //   id: 5,
  //   image: result6,
  // },
  // {
  //   id: 6,
  //   image: result3,
  // },
  // {
  //   id: 7,
  //   image: result8,
  // },
  // {
  //   id: 8,
  //   image: result4,
  // },
  // {
  //   id: 9,
  //   image: result9,
  // },
  // {
  //   id: 10,
  //   image: result5,
  // },
  // {
  //   id: 11,
  //   image: result10,
  // },
];

const HomePage = () => {
  const currentUrl = window.location.href;
  const pathname = new URL(currentUrl).pathname;
  const isProdEnv = process.env.REACT_APP_NODE_ENV_DD === "production";
  //console.log(process.env.REACT_APP_NODE_ENV_DD, "code");

  const seo_title = "Dance Dynamite | Best Dance Competition for Kids";
  const seo_description =
    "Enroll your kids for the dance competition at Dance Dynamite. It covers the vast expanse in pursuit of good dancers who are creative, unique, and zealous.";

  return (
    <>
      <Helmet>
        <link rel="canonical" href={getCannonicalUrl(pathname)} />
        <meta
          name="googlebot"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <meta
          name="robots"
          content={`${isProdEnv ? "index,follow" : "noindex,nofollow"}`}
        />
        <title>{seo_title} </title>
        <meta name="description" content={seo_description} />
        <meta property="og:image" content={Logo} />
        <meta property="og:url" content={currentUrl} />
        <meta property="og:type" content="website" />
      </Helmet>
      <div
        style={{
          marginTop: "65px",
          backgroundColor: "#ffffff",
        }}
      >
        <Carousel arrows autoplay>
          {sliderImages.map((item) => (
            <div
              key={item.id}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={item.image}
                alt={`Dance Dynamite S02 - ${item.id}`}
                style={{
                  width: "100%",
                  height: "100%",
                  objectFit: "cover",
                }}
              />
            </div>
          ))}
        </Carousel>
        <div style={{ padding: "5%" }}>
          <h1 style={{ marginBottom: "3%" }}>
            Dance Dynamite: Dance Competition for Kids
          </h1>
          <div className="dance-dynamite-desc">
            <p style={{ fontSize: "15px" }}>
              Dance Dynamite is the prestigious national-level competition,
              organized in association with Orchids the International School,
              covers the vast expanse of India in pursuit of a singular talent—
              a dancer of such exceptional creativity, uniqueness, and zeal,
              that they stand out as the one in a million.
            </p>
          </div>
          <div
            style={{
              marginBottom: "2%",
            }}
            className="dance-dynamite-desc"
          >
            <p style={{ fontSize: "15px" }}>
              After the great success of season 1, we are delighted to organize
              Inter and Intra school Dance Dynamite season 2 at the pan-India
              level. We are conducting solo & group dance competitions for
              students in the age group 6-15 years. We urge parents to encourage
              their children to participate in the competition to capitalize on
              this great opportunity for them to come into the limelight.
            </p>
          </div>
          <div
            style={{
              marginBottom: "2%",
            }}
            className="dance-dynamite-desc"
          >
            <p style={{ fontSize: "15px" }}>
              The winners of the competition will win exciting prizes & awards.
              We at Orchids look forward to setting the stage for all the
              talented dancers to come and showcase their spark and make this
              event grander.
            </p>
          </div>
          <h2 style={{ padding: "0px 15px" }}>Dance Dynamite Seasons</h2>
          <div className="card-container">
            <div className="card">
              <div className="sampleImage">
                <a class="no-style" href="/season-1">
                  <img src={Season1Img} alt="sample left" />
                </a>
              </div>
             
            </div>
            <div className="card">
              <div className="sampleImage">
                <a class="no-style" href="/season-2">
                  <img src={Season2Img} alt="sample right" />
                </a>
              </div>
             
            </div>
          </div>
          <h2 style={{ padding: "0px 15px", marginTop: "3%" }}>Gallery</h2>
          <div className="news-banner">
            <Carousel1 images={images} autoPlayInterval={5000}/>
          </div> 
          {/* <div className="card-container">
            <div className="card">      
            <h2 style={{ padding: "0px 15px", marginTop: "3%" }}>Season 1</h2>       
            <div className="news-banner">
            <Carousel1 images={images} autoPlayInterval={5000}/>
          </div>               
              </div>
            
            <div className="card">   
            <h2 style={{ padding: "0px 15px", marginTop: "3%" }}>Season 2</h2>             
            <div className="news-banner">
            <Carousel1 images={images}  autoPlayInterval={5000}/>
          </div>  
            </div>
          </div> */}
          
        </div>
      </div>
    </>
  );
};

export default HomePage;
