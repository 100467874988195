import React, { useState } from "react";
import styles from "./Navbar.module.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import {
  faYoutube,
  faInstagramSquare,
  faFacebookSquare,
} from "@fortawesome/free-brands-svg-icons";
//import logo from "../../asset/Image/dance-dynamite-logo-final.png";
import { useLocation, useNavigate, Link } from "react-router-dom";
import secLogo from "../../asset/Image/dance-dynamite-logo-png.png";
const Navbar = () => {
  //const [isScrolled, setIsScrolled] = useState(false);
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleClick = (link) => {
    setActiveLink(link);
  };
  const navigate = useNavigate();
  const handleNavigation = () => {
    navigate("/");
  };
  // useEffect(() => {
  //   const handleScroll = () => {
  //     if (window.scrollY > 50) {
  //       setIsScrolled(true);
  //     } else {
  //       setIsScrolled(false);
  //     }
  //   };
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  return (
    <>
      <div className={styles.navbar}>
        <div className={`${styles.logo}`}>
          {/* <img src={isScrolled ? secLogo : logo} alt={"dance-dynamite"} onClick={handleNavigation}  className={`${isScrolled ? `${styles.scrolled}` : `${styles.scrollimg}`}`}/> */}
          <img
            src={secLogo}
            alt={"dance-dynamite"}
            onClick={handleNavigation}
            className={`${styles.scrolled}`}
          />
        </div>
        <div className={styles.navMenu}>
          <p>
            <Link
              to="/"
              className={activeLink === "/" ? styles.active : styles.inactive}
              onClick={() => handleClick("/")}
            >
              <FontAwesomeIcon icon={faHome} />
            </Link>
          </p>
          <p>
            <Link
              to="/news"
              className={
                activeLink === "/news" ? styles.active : styles.inactive
              }
              onClick={() => handleClick("/news")}
            >
              News
            </Link>
          </p>
          <p>
            <Link
              to="/season-1"
              className={
                activeLink === "/season-1" ? styles.active : styles.inactive
              }
              onClick={() => handleClick("/season-1")}
            >
              Season 1
            </Link>
          </p>
          <p>
            <Link
              to="/season-2"
              className={
                activeLink === "/season-2" ? styles.active : styles.inactive
              }
              onClick={() => handleClick("/season-2")}
            >
              Season 2
            </Link>
          </p>
          
        </div>
        <div className={styles.social}>
          <p>
            <a href="https://www.facebook.com/profile.php?id=61560171257639">
              <FontAwesomeIcon icon={faFacebookSquare} />
            </a>
          </p>
          <p>
            <a href="https://www.instagram.com/dancedynamite_india_/?igsh=MXU2dms3eTM0NHAxYg%3D%3D">
              <FontAwesomeIcon icon={faInstagramSquare} />
            </a>
          </p>
          <p>
            <a href="https://youtu.be/1tPFrq8NmTw?si=ki5ZXcdg3EyhM7BS">
              <FontAwesomeIcon icon={faYoutube} />
            </a>
          </p>
        </div>
      </div>
    </>
  );
};

export default Navbar;
